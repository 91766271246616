import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'

const CardList = loadable(() => import('/src/components/PageBuilder/layouts/standard/CardList'))

const ProcessSteps = ({ backgroundColour, utm }) => {
    const globalQuery = useStaticQuery(
        graphql`
            query {
                component: allWp {
                    nodes {
                        globalBlocks {
                            processSteps {
                                fieldGroupName
                                processSteps {
                                    addTitleArea
                                    title
                                    description
                                    columns
                                    addImages
                                    addTitles
                                    addDescriptions
                                    addCtas
                                    ctaType
                                    itemsRepeater {
                                        image {
                                            id
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(width: 300)
                                                }
                                            }
                                            altText
                                            title
                                        }
                                        title
                                        description
                                        callToAction {
                                            target
                                            title
                                            url
                                        }
                                    }
                                    addCta
                                    callToAction {
                                        target
                                        title
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const globalData = globalQuery.component.nodes

    return globalData.map((node, index) => {
        let componentData = node.globalBlocks.processSteps.processSteps

        const titleArea = {
            addTitleArea: componentData.addTitleArea,
            titlePosition: 'centered',
            title: componentData.title,
            description: componentData.description
        }

        return (
            <CardList
                key={index}
                backgroundColour={backgroundColour}
                titleArea={titleArea}
                columns={componentData.columns}
                addImages={componentData.addImages}
                imageSize={'small'}
                addTitles={componentData.addTitles}
                addDescription={componentData.addDescriptions}
                addCtas={componentData.addCtas}
                ctaType={componentData.ctaType}
                itemsRepeater={componentData.itemsRepeater}
                addCta={componentData.addCta}
                callToAction={componentData.callToAction}
                className={'c-process-steps'}
                utm={utm}
            />
        )
    })
}

ProcessSteps.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string
}

ProcessSteps.defaultProps = {
    backgroundColour: 'light'
}

export default ProcessSteps

export const query = graphql`
    fragment ProcessStepsForPageBuilder on WpPage_Acfpagebuilder_Layouts_ProcessSteps {
        fieldGroupName
        backgroundColour
    }
`
